import payload_plugin_NEx9oIfTEL from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_YTGlmQWWT5 from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_1QOsfnRqbu from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.js";
import vuetify_icons_tuyYNCzpQc from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_neSs9z3UJp from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/Users/adria-fsbd/Documents/FSBD-Projects/app/andalucia/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_lCxu0ZmxIx from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_6xSY8Eag1D from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_5C7QFp7tsq from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import vuetify_no_client_hints_hj4IM0CtsZ from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_client_9L1MKQjYBH from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_pnx92hQQEP from "/Users/adria-fsbd/Documents/FSBD-Projects/app/andalucia/node_modules/.cache/nuxt/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_irNqdQfFTT from "/Users/adria-fsbd/Documents/FSBD-Projects/app/andalucia/node_modules/.cache/nuxt/.nuxt/vuefire-plugin.mjs";
import plugin_client_xU0rMl9f3U from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.js";
import directives_2wosUsxYPa from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/plugins/directives.ts";
import sentry_client_eQF2wzdEb1 from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/plugins/sentry.client.ts";
import toastify_ziSmkPd8w1 from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/plugins/toastify.ts";
import ssg_detect_O3dFMwF5H0 from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
import vuetify_nuxt_plugin_client_moE2XX1Cig from "/Users/adria-fsbd/Documents/FSBD-Projects/app/andalucia/node_modules/.cache/nuxt/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_NEx9oIfTEL,
  revive_payload_client_YTGlmQWWT5,
  vuetify_i18n_1QOsfnRqbu,
  vuetify_icons_tuyYNCzpQc,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  components_plugin_zlvi6dcIsi,
  prefetch_client_8FoqvPFTot,
  switch_locale_path_ssr_lCxu0ZmxIx,
  route_locale_detect_6xSY8Eag1D,
  i18n_5C7QFp7tsq,
  vuetify_no_client_hints_hj4IM0CtsZ,
  plugin_client_9L1MKQjYBH,
  vuefire_auth_plugin_client_pnx92hQQEP,
  vuefire_plugin_irNqdQfFTT,
  plugin_client_xU0rMl9f3U,
  directives_2wosUsxYPa,
  sentry_client_eQF2wzdEb1,
  toastify_ziSmkPd8w1,
  ssg_detect_O3dFMwF5H0,
  vuetify_nuxt_plugin_client_moE2XX1Cig
]